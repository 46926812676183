(function breadcrumbsScript($, Drupal, once) {
  Drupal.behaviors.breadcrumbs = {
    attach(context) {

      const $breadcrumbsShareTrigger = $('.breadcrumbs__share-btn');
      const $breadcrumbsShareIconsContainer = $('.breadcrumbs__share-items');

      // Show share items toggle button.
      once('breadcrumbsShareTrigger', () => {
        $breadcrumbsShareTrigger.attr('hidden', false);
      });
      $breadcrumbsShareTrigger.attr('aria-expanded', "false");
      $breadcrumbsShareIconsContainer.addClass('js-breadcrumbs__share-items');

      $breadcrumbsShareTrigger.unbind('click').click(function () {
        // remove hidden attribute from ShareIconsContainer as hidden via CSS.
        once('breadcrumbsShareIconsContainer', () => {
          $breadcrumbsShareIconsContainer.attr('hidden', false);
        });
        // Get the expanded status.
        let expanded = $breadcrumbsShareTrigger.attr('aria-expanded') == 'true' || false;
        $(this).attr('aria-expanded', !expanded);

      });

    },
  };
}(jQuery, Drupal, once));
